export const PAGINATION_SIZE = 8;
export const DEFAULT_GRID_IMG_LOAD_EAGER_COUNT = 4;
export const ATTR_LOADING_EAGER = 'eager';

export function getImageLoadingPriority(
  index: number,
  maxEagerLoadCount = DEFAULT_GRID_IMG_LOAD_EAGER_COUNT,
) {
  return index < maxEagerLoadCount ? ATTR_LOADING_EAGER : undefined;
}

export const DOMAIN_TO_HANDLE: Record<string, string> = {
  'myuglyclementine.shop': 'my-ugly-clementine-shop',
  'lenny-kravitz.store': 'lenny-kravitz-store-final',
  'lennykravitz.eu': 'lenny-kravitz-eu',
  'shop.antjeschomaker.de': 'antje-schomaker-s-snack-shop',
  'charismaatisch.com': 'charismas-wardrobe',
  'kapitel1.sebastianwurth-shop.de': 'sebastian-wurth-kapitel-1-shop',
  'liaze-shop.de': 'liaze-shop',
  'aylo-therapie.de': 'aylo-shop'
};

export const domainToHandle = (domain: string) => {
  let normalized = domain.replace(/(^\w+:|^)\/\//, '');
  // remove www
  normalized = normalized.replace(/^www\./, '');

  return DOMAIN_TO_HANDLE[normalized] ?? null;
};
