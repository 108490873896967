import {
  type EnhancedMenu,
  type EnhancedMenuItem,
  useIsHomePath,
} from '~/lib/utils';
import {
  Drawer,
  useDrawer,
  Text,
  Input,
  IconAccount,
  IconBag,
  IconSearch,
  Heading,
  IconMenu,
  IconCaret,
  Section,
  CountrySelector,
  Cart,
  CartLoading,
  Link,
} from '~/components';
import {useParams, Form, Await, useMatches} from '@remix-run/react';
import {useWindowScroll} from 'react-use';
import {Disclosure} from '@headlessui/react';
import {Suspense, useEffect, useMemo, useState} from 'react';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import type {LayoutData} from '../root';

export function Layout({
  children,
  layout,
  onepage,
  pwCorrect,
}: {
  children: React.ReactNode;
  layout: LayoutData;
  pwCorrect?: boolean;
  onepage?: {
    title?: string;
    logo_position?: 'left' | 'center';
    logo?: {
      reference?: {id?: string; image?: {altText?: string; url?: string}};
    };
  };
}) {
  const menus =
    (onepage as any)?.menus?.references?.nodes.map((node: any) => {
      return {
        title: node.title?.value,
        links: node.links?.references?.nodes.map((n: any) => {
          return {
            label: n.label?.value,
            url: n.url?.value,
          };
        }),
      };
    }) ?? [];
  const footerImage = (onepage as any)?.footerImage?.reference?.image?.url;

  const countdownTime = (onepage as any)?.countdown;
  const countdownActive =
    countdownTime && new Date(countdownTime) > new Date() && !pwCorrect;

  return (
    <>
      {countdownActive && <CountdownPage endTime={countdownTime} />}
      {!countdownActive && (
        <>
          <div className="flex flex-col min-h-screen">
            <div className="">
              <a href="#mainContent" className="sr-only">
                Skip to content
              </a>
            </div>
            <Header
              title={onepage?.title ?? 'Hydrogen'}
              logo={onepage?.logo?.reference?.image?.url}
              logoPosition={onepage?.logo_position ?? 'left'}
              menu={layout?.headerMenu}
            />
            <main role="main" id="mainContent" className="flex-grow">
              {children}
            </main>
          </div>
          <Footer menus={menus} footerImage={footerImage} />
        </>
      )}
    </>
  );
}

function CountdownPage({endTime}: {endTime: string}) {
  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const end = new Date(endTime).getTime();
      const diff = end - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setRemainingTime({days, hours, minutes, seconds});
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  return (
    <div className="min-h-screen bg-bgcolor flex items-center justify-center text-primary">
      <div className="flex flex-wrap justify-center">
        <div className="flex flex-col items-center mx-4 my-2">
          <span className="text-5xl font-bold text-contrast">
            {remainingTime.days}
          </span>
          <span className="text-2xl font-semibold">Days</span>
        </div>
        <div className="flex flex-col items-center mx-4 my-2">
          <span className="text-5xl font-bold text-contrast">
            {remainingTime.hours}
          </span>
          <span className="text-2xl font-semibold">Hours</span>
        </div>
        <div className="flex flex-col items-center mx-4 my-2">
          <span className="text-5xl font-bold text-contrast">
            {remainingTime.minutes}
          </span>
          <span className="text-2xl font-semibold">Minutes</span>
        </div>
        <div className="flex flex-col items-center mx-4 my-2">
          <span className="text-5xl font-bold text-contrast">
            {remainingTime.seconds}
          </span>
          <span className="text-2xl font-semibold">Seconds</span>
        </div>
      </div>
    </div>
  );
}

export default CountdownPage;

function Header({
  title,
  menu,
  logo,
  logoPosition,
}: {
  title: string;
  menu?: EnhancedMenu;
  logo?: string;
  logoPosition?: 'left' | 'center';
}) {
  const isHome = true; // useIsHomePath();

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  return (
    <>
      <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
      {/*menu && false && (
        <MenuDrawer isOpen={isMenuOpen} onClose={closeMenu} menu={menu} />
      )*/}
      <DesktopHeader
        isHome={isHome}
        title={title}
        logo={logo}
        logoPosition={logoPosition}
        x-menu={menu}
        openCart={openCart}
      />
      <MobileHeader
        isHome={isHome}
        title={title}
        logo={logo}
        openCart={openCart}
        openMenu={openMenu}
      />
    </>
  );
}

function CartDrawer({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) {
  const [root] = useMatches();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      heading="Warenkorb"
      openFrom="right"
    >
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}

export function MenuDrawer({
  isOpen,
  onClose,
  menu,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: EnhancedMenu;
}) {
  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left" heading="Menu">
      <div className="grid">
        <MenuMobileNav menu={menu} onClose={onClose} />
      </div>
    </Drawer>
  );
}

function MenuMobileNav({
  menu,
  onClose,
}: {
  menu: EnhancedMenu;
  onClose: () => void;
}) {
  return (
    <nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8">
      {/* Top level menu items */}
      {(menu?.items || []).map((item) => (
        <span key={item.id} className="block">
          <Link
            to={item.to}
            target={item.target}
            onClick={onClose}
            className={({isActive}) =>
              isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
            }
          >
            <Text as="span" size="copy">
              {item.title}
            </Text>
          </Link>
        </span>
      ))}
    </nav>
  );
}

function MobileHeader({
  title,
  isHome,
  openCart,
  openMenu,
  logo,
}: {
  title: string;
  isHome: boolean;
  openCart: () => void;
  openMenu: () => void;
  logo?: string;
}) {
  // useHeaderStyleFix(containerStyle, setContainerStyle, isHome);

  const params = useParams();

  return (
    <header
      role="banner"
      className={`${
        isHome
          ? 'bg-secondary/80  text-contrast  shadow-darkHeader'
          : 'bg-contrast/80 text-primary'
      } flex lg:hidden items-center h-nav sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8`}
    >
      <div className="flex items-center justify-start w-full gap-4">
        {/*<button
          onClick={openMenu}
          className="relative flex items-center justify-center w-8 h-8"
        >
          <IconMenu />
        </button>
        <Form
          method="get"
          action={params.lang ? `/${params.lang}/search` : '/search'}
          className="items-center gap-2 sm:flex"
        >
          <button
            type="submit"
            className="relative flex items-center justify-center w-8 h-8"
          >
            <IconSearch />
          </button>
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 '
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder="Search"
            name="q"
          />
          </Form>*/}
      </div>

      <Link
        className="flex items-center self-stretch leading-[3rem] md:leading-[4rem] justify-center flex-grow w-full h-full"
        to="/#home"
      >
        <Heading
          className="font-bold text-center h-full"
          as={isHome ? 'h1' : 'h2'}
        >
          {logo ? <img src={logo} alt={title} className="h-full object-contain" /> : title}
        </Heading>
      </Link>

      <div className="flex items-center justify-end w-full gap-4">
        {/*<Link
          to="/account"
          className="relative flex items-center justify-center w-8 h-8"
        >
          <IconAccount />
        </Link>*/}
        <CartCount isHome={isHome} openCart={openCart} />
      </div>
    </header>
  );
}

function DesktopHeader({
  isHome,
  menu,
  openCart,
  title,
  logo,
  logoPosition,
}: {
  isHome: boolean;
  openCart: () => void;
  menu?: EnhancedMenu;
  title: string;
  logo?: string;
  logoPosition: 'left' | 'center';
}) {
  const params = useParams();
  const {y} = useWindowScroll();
  return (
    <header
      role="banner"
      className={`${
        isHome
          ? 'bg-secondary/80  text-contrast  shadow-darkHeader'
          : 'bg-contrast/80 text-primary'
      } ${
        !isHome && y > 50 && ' shadow-lightHeader'
      } hidden h-nav lg:flex items-center sticky transition duration-300 backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-8 px-12`}
    >
      <div
        className={`${
          logoPosition !== 'left' ? 'opacity-0' : ''
        }  flex w-1/3 gap-12 relative ${logo && 'h-full'}`}
      >
        <Link className="font-bold" to="/" prefetch="intent">
          {logo ? <img src={logo} alt={title} className="h-full" /> : title}
        </Link>
        <nav className="flex gap-8">
          {/* Top level menu items */}
          {(menu?.items || []).map((item) => (
            <Link
              key={item.id}
              to={item.to}
              target={item.target}
              prefetch="intent"
              className={({isActive}) =>
                isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
              }
            >
              {item.title}
            </Link>
          ))}
        </nav>
      </div>
      <div
        className={`${
          logoPosition !== 'center' ? 'opacity-0' : ''
        } flex w-1/3 gap-12 relative justify-center ${logo && 'h-full'}`}
      >
        <Link
          className="font-bold flex justify-center"
          to="/"
          prefetch="intent"
        >
          {logo ? <img src={logo} alt={title} className="h-full object-contain" /> : title}
        </Link>
      </div>
      <div className="flex w-1/3 items-center justify-end gap-1">
        {/*<Form
          method="get"
          action={params.lang ? `/${params.lang}/search` : '/search'}
          className="flex items-center gap-2"
        >
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 '
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder="Search"
            name="q"
          />
          <button
            type="submit"
            className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
          >
            <IconSearch />
          </button>
          </Form>*/}
        {/*<Link
          to="/account"
          className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
        >
          <IconAccount />
        </Link>*/}
        <CartCount isHome={isHome} openCart={openCart} />
      </div>
    </header>
  );
}

function CartCount({
  isHome,
  openCart,
}: {
  isHome: boolean;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <Suspense
      fallback={<Badge count={0} dark={false && isHome} openCart={openCart} />}
    >
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <Badge
            dark={false && isHome}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function Badge({
  openCart,
  dark,
  count,
}: {
  count: number;
  dark: boolean;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();

  const _BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`${
            dark ? 'text-primary bg-contrast  ' : 'text-contrast bg-primary'
          } absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count, dark],
  );

  const BadgeCounter = useMemo(
    () => (
      <>
        <span className="hidden lg:inline">Warenkorb</span>
        <div className="lg:hidden h-5 w-5 relative">
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M372 160v-12A116.13 116.13 0 0 0 258.89 32h-5.78A116.13 116.13 0 0 0 140 148v12H52a4 4 0 0 0-4 4v300a16 16 0 0 0 16 16h384a16 16 0 0 0 16-16V164a4 4 0 0 0-4-4Zm-40 0H180v-11c0-41.84 33.41-76.56 75.25-77A76.08 76.08 0 0 1 332 148Z"
            />
          </svg>
          <div className="absolute top-0 right-0 h-5 w-5 text-[8px] mt-[2px] flex justify-center items-center text-white">
            {count}
          </div>
        </div>
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}

interface Menu {
  title: string;
  links: Link[];
}

interface Link {
  label: string;
  url: string;
}

interface FooterProps {
  menus: Menu[];
  footerImage?: string;
}

function BMGFooterMenu({title, links}: Menu) {
  return (
    <div className="py-4 text-contrast">
      <h3 className="text-lg font-semibold">{title}</h3>
      <ul className="mt-4">
        {links.map((link) => (
          <li key={link.url} className="mb-2">
            <a
              href={link.url}
              target="_blank"
              className="hover:underline"
              rel="noreferrer"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

function Footer({menus, footerImage}: FooterProps) {
  const isHome = useIsHomePath();

  return (
    <>
      <Section
        divider={isHome ? 'none' : 'top'}
        as="footer"
        role="contentinfo"
        className={`grid min-h-[10rem] grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4
        bg-secondary   text-contrast overflow-hidden`}
      >
        {menus.map((menu) => (
          <BMGFooterMenu
            key={menu.title}
            title={menu.title}
            links={menu.links}
          />
        ))}
        {footerImage && (
          <img
            className="h-full lg:h-auto object-contain"
            src={footerImage}
            alt=""
          ></img>
        )}
        {/*<FooterMenu menu={menu} />
      <CountrySelector />*
        <div className={`self-end pt-8 opacity-50 md:col-span-2`}>
          
          <br />
          <br />
          <Link to="/policies" prefetch="intent">
            Rechtliches
          </Link>
        </div>*/}
      </Section>
      <div className="text-black text-left py-4 px-6 bg-white">
        &copy; {new Date().getFullYear()} -{' '}
        <Link to="/policies" prefetch="intent">
          Rechtliches
        </Link>{' '}
        - This store is part of <i>The Art of the Album</i> and shares one
        checkout for payments.
      </div>
    </>
  );
}

const FooterLink = ({item}: {item: EnhancedMenuItem}) => {
  if (item.to.startsWith('http')) {
    return (
      <a href={item.to} target={item.target} rel="noopener noreferrer">
        {item.title}
      </a>
    );
  }

  return (
    <Link to={item.to} target={item.target} prefetch="intent">
      {item.title}
    </Link>
  );
};

function FooterMenu({menu}: {menu?: EnhancedMenu}) {
  const styles = {
    section: 'grid gap-4',
    nav: 'grid gap-2 pb-6',
  };

  return (
    <>
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <section key={item.id} className={styles.section}>
          <Disclosure>
            {({open}) => (
              <>
                <Disclosure.Button className="text-left md:cursor-default">
                  <Heading className="flex justify-between" size="lead" as="h3">
                    {item.title}
                    {item?.items?.length > 0 && (
                      <span className="md:hidden">
                        <IconCaret direction={open ? 'up' : 'down'} />
                      </span>
                    )}
                  </Heading>
                </Disclosure.Button>
                {item?.items?.length > 0 ? (
                  <div
                    className={`${
                      open ? `max-h-48 h-fit` : `max-h-0 md:max-h-fit`
                    } overflow-hidden transition-all duration-300`}
                  >
                    <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                      <Disclosure.Panel static>
                        <nav className={styles.nav}>
                          {item.items.map((subItem) => (
                            <FooterLink key={subItem.id} item={subItem} />
                          ))}
                        </nav>
                      </Disclosure.Panel>
                    </Suspense>
                  </div>
                ) : null}
              </>
            )}
          </Disclosure>
        </section>
      ))}
    </>
  );
}
